<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs6 no-padding">
						<h1>Adoções</h1>
					</div>
					<div class="flex xs6 no-padding text-right">
						<br /><br />
						<button v-on:click="showProject('post')" class="btn btn-change">
							ADICIONAR ADOÇÃO
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="tab">
						<button class="active" @click="changePage('adoptions-consultant')">
							Adoções ativas
						</button>
						<button @click="changePage('adoptions-consultant-pending')">
							Adoções pendentes
						</button>
					</div>

					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list va-row">
										<div class="va-row">
											<div class="flex xs3 no-padding-left">
												<div class="input-group">
													<label>Status</label>
													<select-normal
														:items="listStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectStatus"
														@change="onChangeFilterStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<div class="input-group">
													<label>Pagamento</label>
													<select-normal
														:items="listPayments"
														:value="''"
														label="Forma de pagamento"
														name="typeName"
														v-model="selectPayments"
														@change="onChangeFilterPayment($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<label>Faturamento</label>
												<div class="input-group">
													<select-normal
														:items="listPaymentStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectPaymentStatus"
														@change="onChangeFilterPaymentStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3 no-padding-right">
												<label>Tipo de contrato</label>
												<div class="input-group">
													<select-normal
														:items="listContracts"
														:value="''"
														label="Selecione um contrato"
														name="typeName"
														v-model="selectContracts"
														@change="onChangeFilterContracts($event)"
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										class="sets-list table-list va-row"
										v-if="list.length > 0"
									>
										<div class="flex md12 header no-padding">
											<div class="va-row">
												<div
													class="flex md4"
													:class="
														!consultantCredential.settings.bill_button &&
														!consultantCredential.settings.invoice_button
															? 'md8'
															: 'md4'
													"
												></div>

												<div class="flex md2 text-center">Livros da adoção</div>
												<div class="flex md2 text-center">
													Previsão de entrega
												</div>

												<div class="flex md2 text-center"></div>

												<div class="flex md2 text-center"></div>

												<div class="flex xs1 text-right"></div>
											</div>
										</div>

										<div class="flex md12 no-padding">
											<div
												class="flex md12 no-padding"
												v-for="item in list"
												:key="item.id"
											>
												<div
													class="va-row lines"
													v-if="item.status !== 'pending'"
												>
													<div
														class="flex md4 item"
														:class="
															!consultantCredential.settings.bill_button &&
															!consultantCredential.settings.invoice_button
																? 'md8'
																: 'md4'
														"
													>
														<div class="va-row">
															<div class="flex xs12 text-left">
																<button
																	class="btn btn-clean"
																	@click="openGrade(item)"
																>
																	{{ item.name }} &nbsp;<i
																		class="fa fa-external-link"
																		aria-hidden="true"
																	></i>
																</button>
																<span class="created-data init"
																	>Início: {{ item.payment }}</span
																>
																<span class="created-data date"
																	>Status: {{ item.status_label }}</span
																>
																<span class="created-data contract"
																	>Tipo de contrato:
																	{{ item.contract_method }}</span
																>
															</div>
														</div>

														<div class="va-row">
															<div class="flex xs12"></div>
														</div>
													</div>

													<div class="flex md2 item dates">
														<div class="va-row">
															<div class="flex md12 no-padding">
																{{ item.books_completed }} /
																{{ item.books_ordered }}
															</div>
															<div
																class="flex md12 no-padding"
																v-if="
																	consultantCredential.settings
																		.release_print_button
																"
															>
																<span
																	class="created-data print"
																	@click="openModalConfirmation('print', item)"
																	>Liberar impressão</span
																>
															</div>
														</div>
													</div>
													<div class="flex md2 item dates">
														{{ item.delivery }}
													</div>

													<div
														class="flex md4 item dates"
														v-if="
															consultantCredential.settings.bill_button ||
															consultantCredential.settings.invoice_button
														"
													>
														<div class="va-row">
															<div class="flex md12 no-padding"></div>

															<div class="flex md12 no-padding">
																<span
																	class="created-data pay"
																	@click="openModalConfirmation('nf', item)"
																	v-if="
																		consultantCredential.settings.bill_button
																	"
																	>Gerar Nota Fiscal</span
																>
																<span
																	v-if="
																		consultantCredential.settings.bill_button &&
																		consultantCredential.settings.invoice_button
																	"
																>
																	|
																</span>
																<span
																	class="created-data pay"
																	@click="
																		openModalConfirmation('checkout', item)
																	"
																	v-if="
																		consultantCredential.settings.invoice_button
																	"
																	>Gerar Pagamento</span
																>
															</div>
														</div>
													</div>

													<!-- <div class="flex md2 item dates">
                                      <div class="va-row">
                                          <div class="flex md12 no-padding">
                                            <span class="created-data print" @click="openModalConfirmation('checkout', item)">Gerar Pagamento</span>
                                          </div>
                                      </div>  
                                    </div>

                                    <div class="flex md2 item last-item">
                                      <div class="va-row">
                                          <div class="flex md12 no-padding">
                                            <span class="created-data print" @click="openModalConfirmation('nf', item)">Gerar Nota Fiscal</span>
                                          </div>
                                        </div>
                                    </div> -->
												</div>

												<div class="va-row pendent" v-else>
													<div class="flex md4 item">
														{{ item.name }}
													</div>
													<div class="flex md2 item dates">
														<button class="btn" v-if="!item.step_1_status">
															Produto
														</button>
														<span
															v-else
															:class="
																item.step_1_status ? 'active' : 'inactive'
															"
															>Produto</span
														>
													</div>

													<div class="flex md2 item dates">
														<button
															class="btn"
															v-if="item.step_1_status && !item.step_2_status"
															@click="openEdit(2, item.id, 'put')"
														>
															Faturamento
														</button>
														<span
															v-else
															:class="
																item.step_2_status ? 'active' : 'inactive'
															"
															>Faturamento</span
														>
													</div>

													<div class="flex md2 item dates">
														<button
															class="btn"
															v-if="
																item.step_1_status &&
																item.step_2_status &&
																!item.step_3_status
															"
															@click="openEdit(3, item.id, 'put')"
														>
															Entrega
														</button>
														<span
															v-else
															:class="
																item.step_3_status ? 'active' : 'inactive'
															"
															>Entrega</span
														>
													</div>

													<div class="flex md2">
														<button
															class="btn"
															v-if="
																item.step_1_status &&
																item.step_2_status &&
																item.step_3_status
															"
															@click="sendRelease(item)"
														>
															Liberar
														</button>
														<span
															v-else
															:class="
																item.step_1_status &&
																item.step_2_status &&
																item.step_3_status
																	? 'active'
																	: 'inactive'
															"
															>Liberado</span
														>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<vuestic-modal
									:show.sync="show"
									:titleDisabled="false"
									v-bind:large="true"
									id="adoptionEdit"
									ref="largeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<adoption-edit></adoption-edit>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="optionModalModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title" v-if="typeModalconfirm === 'nf'">
										Nota fiscal
									</div>
									<div slot="title" v-if="typeModalconfirm === 'checkout'">
										Reenviar cobrança
									</div>
									<div slot="title" v-if="typeModalconfirm === 'print'">
										Liberar livros
									</div>

									{{ confirmMessage }}

									<br /><br />

									<div class="error-label text-center">
										{{ errors.first("error-modal") }}
									</div>

									<hr />
									<br />

									<div class="va-row text-right">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-primary cancel"
												@click="closeModal()"
											>
												CANCELAR
											</button>

											<button
												class="btn right"
												v-if="typeModalconfirm === 'nf'"
												@click="sendNF(currentItemModal)"
											>
												CONFIRMAR
											</button>
											<button
												class="btn right"
												v-if="typeModalconfirm === 'checkout'"
												@click="sendCheckout(currentItemModal)"
											>
												CONFIRMAR
											</button>
											<button
												class="btn right"
												v-if="typeModalconfirm === 'print'"
												@click="sendPrint(currentItemModal)"
											>
												CONFIRMAR
											</button>
										</div>
									</div>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="miniModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title">Sucesso</div>

									{{ customResponse }}

									<br /><br />
									<hr />
									<br />
									<div class="va-row">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-white right cancel"
												@click="closeModal()"
											>
												OK, ENTENDI
											</button>
										</div>
									</div>
								</vuestic-modal>
							</div>
						</div>
					</div>
				</div>
			</div>

			<infinite-loading
				v-if="filterLoaded"
				:identifier="[
					selectStatus,
					selectPayments,
					selectPaymentStatus,
					selectContracts,
				]"
				@infinite="getProjects"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import Vue from "vue";
	window.Vue = Vue;

	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				consultantCredential: "consultantCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],
				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],

				filterLoaded: false,
				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",

				projectModalType: null,

				show: false,

				customResponse: "",

				typeModalconfirm: "",
				confirmMessage: "",
				currentItemModal: null,

				upload: false,
			};
		},

		mounted() {
			const self = this;

			self.getFilters();

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// CRUD SCHOOLS
			this.$root.$on("addProject", (project) => {
				//school.grades = []
				//self.list.push(project)
				//self.closeModal();
				//self.$router.push({ name: 'adoptions-pending' })
			});

			this.$root.$on("editProject", (project, step) => {
				var result = self.list.filter((item) => {
					return item.id === project.id;
				});

				if (result.length > 0) {
					var index = self.list.indexOf(result[0]);

					if (step === 2) {
						self.list[index].step_2_status = true;
					} else if (step === 3) {
						self.list[index].step_3_status = true;
					}
				}
			});
		},

		methods: {
			getFilters() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
					.then((response) => {
						self.filters = response.data.data;

						self.listStatus = response.data.data.status.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPayments = response.data.data.payments.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPaymentStatus = response.data.data.payment_status.map(
							(s) => ({
								key: s.id,
								value: s.name,
							})
						);

						self.listContracts = response.data.data.contracts.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.filterLoaded = true;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			changePage(url) {
				this.$router.push({ name: url });
			},

			openModalConfirmation(type, item) {
				const self = this;

				self.currentItemModal = item;

				self.$refs.optionModalModal.open();
				self.typeModalconfirm = type;

				if (type === "nf") {
					self.confirmMessage =
						"Ao confirmar será enviado a nota fiscal para o responsável da escola: " +
						item.name;
				} else if (type === "print") {
					self.confirmMessage =
						"Você deseja liberar os livros para impressão? Lembre-se de certificar se o pagamento já foi realizado antes de confirmar o envio para produção.";
				} else if (type === "checkout") {
					self.confirmMessage =
						"Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola: " +
						item.name;
				}
			},

			sendCheckout(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/send_checkout/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();

						//alert("CHECKOUT");
						//self.list = response.data.data.projects
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendNF(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/create_invoice/" +
							item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendPrint(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/release_print/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendRelease(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.consultantCredential.token,
					},
				};

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/projects/release",
						{ project_id: item.id },
						config
					)
					.then(function (response) {
						var result = self.list.filter((itemLista) => {
							return itemLista.id === item.id;
						});

						var index = self.list.indexOf(result[0]);
						Vue.set(self.list, index, response.data.data.project);
					})
					.catch(function (error) {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			openGrade(item) {
				const self = this;

				self.$store.commit("SAVE_CONSULTANT_CURRENT_ADOPTION", item);
				self.$router.push({ name: "grades-consultant" });
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}

				if (this.$refs.miniModal) {
					this.$refs.miniModal.close();
				}

				if (this.$refs.optionModalModal) {
					this.$refs.optionModalModal.close();
				}

				//this.show = false
			},

			openEdit(order, project_id, type) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/steps/" + project_id,
						config
					)
					.then((response) => {
						var project = response.data.data.project;
						project.type = type;
						project.step = order;
						//project.id = project_id

						if (order === 2 && !project.address) {
							project.cnpj = "";
							project.address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};

							project.contact_name = "";
							project.contact_email = "";
							project.contact_phone = "";
							project.contract_method_id = "";
							project.payment_method_id = "";
							project.ie = "";
							project.razao_social = "";
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						if (order === 3 && !project.shipping_address) {
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						self.$store.commit("SAVE_CONSULTANT_CURRENT_ADOPTION", project);
						self.project_id = project.id;
						self.$refs.largeModal.open();
						//self.show = true
					})
					.catch((e) => {
						self.errors.push(e);
					});
			},

			showProject(type) {
				const self = this;

				this.projectModalType = type;
				this.$store.commit("CLEAR_CONSULTANT_CURRENT_ADOPTION");

				//this.show = true
				this.$refs.largeModal.open();
			},

			onChangeFilterStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectStatus = event.target.value;
				//this.getProjects()
			},

			onChangeFilterPayment(event) {
				this.page = 1;
				this.list = [];
				this.selectPayments = event.target.value;
				//this.getProjects()
			},

			onChangeFilterPaymentStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectPaymentStatus = event.target.value;
				//this.getProjects()
			},

			onChangeFilterContracts(event) {
				this.page = 1;
				this.list = [];
				this.selectContracts = event.target.value;
				//this.getProjects()
			},

			getProjects($state) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				//let url = '/projects?page=1&limit=100&status='+ self.selectedStatus +'&payment_status='+ self.selectedPaymentStatus +'&payments='+ self.selectedPayments +'&contracts='+ self.selectedContracts;
				let url =
					"/projects?page=" +
					self.page +
					"&limit=25&status=" +
					self.selectStatus +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&payments=" +
					self.selectPayments +
					"&contracts=" +
					self.selectContracts;

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						if (response.data.data.projects.length) {
							response.data.data.projects.forEach((element) => {
								self.list.push(element);
							});
							self.page += 1;
							$state.loaded();
						} else {
							$state.complete();
						}

						//self.list = response.data.data.projects
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	.created-data {
		font-size: 12px;
		display: block;
		color: #afa2ba;

		&.init {
			margin-bottom: 10px;
		}

		&.nf {
			cursor: pointer;
			color: #4965bc;

			&:hover {
				text-decoration: underline;
			}
		}

		&.print {
			cursor: pointer;
			color: #4965bc;
			width: 100%;

			&:hover {
				text-decoration: underline;
			}
		}

		&.pay {
			cursor: pointer;
			color: #4965bc;
			// width: 100%;
			display: contents;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.btn-clean {
		background-color: #fff;
		color: #4965bc;
		text-decoration: none;
		background-color: transparent;
		border: 0px;
		padding: 0px;
		font-size: 16px;
		font-weight: 300;
		font-family: "Roboto", sans-serif;
		letter-spacing: 0px;
		text-align: left;

		&:hover {
			text-decoration: underline;
		}
	}

	.table-list {
		border: solid 1px #dfdae4;
		border-radius: 15px;
		overflow: hidden;
		margin-top: 15px !important;
		width: 100%;
	}

	.button-actions {
		.btn {
			padding: 10px;
			border-radius: 0px;
			font-size: 10px;

			&.btn-blue {
				background-color: rgb(74, 144, 226);
				max-width: 192px;
			}
		}
	}

	.pendent {
		background-color: #fff;
		span {
			color: gray;

			&.active {
				color: green;
				font-weight: bold;
			}
		}
	}
	.btn-blue {
		// background-color:rgb(74, 144, 226);
		max-width: 192px;
	}

	.sets-list {
		font-size: 14px;

		.lines {
			width: 100%;

			border-bottom: solid 1px #dfdae4;

			.item {
				border-right: solid 1px #dfdae4;

				&.dates {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;

					-ms-flex-align: center;
					-webkit-align-items: center;
					-webkit-box-align: center;

					align-items: center;
					text-align: center;
					justify-content: center;

					.item-text {
						padding-top: 0px !important;
					}

					i {
						font-size: 25px;
					}

					.non-checked {
						color: rgba(0, 0, 0, 0.1);
					}
					.checked-item {
						i {
							color: #19dea3;
						}
					}
				}

				&.last-item {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;

					-ms-flex-align: center;
					-webkit-align-items: center;
					-webkit-box-align: center;

					align-items: center;
					text-align: center;
					justify-content: center;

					border-right: 0px;
					// .item-text{
					//   padding-top: 0px !important;
					// }

					// i{
					//   font-size: 25px;
					// }

					// .non-checked{
					//   color: rgba(0, 0, 0, 0.1);
					// }
					// .checked-item{
					//   i{
					//     color: #19dea3;
					//   }
					// }
				}

				&.tracking-code {
					border-right: none !important;
					text-align: center;
					justify-content: center;

					a {
						text-align: center;
						justify-content: center;
						font-size: 16px;
					}
				}
			}
		}

		.header {
			background-color: #f8f7fc;
			border-bottom: solid 1px #dfdae4;
			color: #afa2ba;
			height: 61px;
			line-height: 45px;
			padding: 0px 20px;
			font-size: 12px;
		}

		::v-deep .select {
			font-size: 14px !important;
		}

		.full-line {
			margin: 0 auto;
			margin-bottom: 5px;
		}

		.flex {
			&.success {
				color: #000;
			}

			&.danger {
				background-color: #fff0f0;
			}
		}
	}

	.schedule-list {
		text-align: center;

		.bullet {
			display: flex;
			width: 110px;
			height: 110px;
			border-radius: 60px;
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}

		.blocks {
			padding: 10px 15px;
			margin-bottom: 15px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}
	}
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// .va-row{
	//   margin: 0 !important;
	// }
	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 0px 0px 10px 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;
		border-top: 0px;
		margin-bottom: 20px;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	::v-deep h3 {
		font-size: 16px !important;
		margin-top: 0px !important;
		width: 100%;
	}

	::v-deep .vuestic-modal {
		width: 50px;
		.modal-dialog {
			max-width: 720px !important;
		}
	}
</style>
